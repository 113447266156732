<!-- Friendly fallback for unmatched routes -->

<template>
  <div class="container">
    <h1>Not Found</h1>
    <p>Sorry, this content was not found. Maybe you'd like to go back.</p>
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
  }
</script>
